<template>
    <div class="action-schedule">
        <div class="message m-b-8">
            <div class="message-top">
                <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                <span v-html="introduction.title" />
            </div>
            <div class="message-content">
                <div class="desc" v-html="introduction.desc" />
                <span class="desc-detail m-t-9">
                    *유의사항* 약속을 확정하지 않으면 만남 의사가 없는 것으로 간주하고 약속이 자동 취소처리되며, 미진행
                    수수료 2만원이 부과됩니다.
                </span>
                <div class="grey-box">
                    <div class="user-profile">
                        <img :src="photoUrl" alt="" />
                        <div class="profile-info">
                            <div class="age" v-html="age" />
                            <div class="region" v-html="content.region" />
                            <div class="job" v-html="content.job" />
                        </div>
                    </div>
                </div>
                <div v-if="gender === 1" class="grey-box m-t-8">
                    <div class="title-grey">약속 내용</div>
                    <!--   하드코딩  -->
                    <div class="content-grey">
                        <p class="m-b-4">{{ time }}</p>
                        <p v-if="isOnePlace">{{ content.meet_place_1 }}에서 {{ meetType }} 제안</p>
                        <p v-else>{{ content.meet_place_1 }} 외 {{ places.length - 1 }}곳에서 {{ meetType }} 제안</p>
                    </div>
                </div>
                <div v-else class="grey-box m-t-8">
                    <ScheduleProgress :mode="gender === 1 ? 'a' : 'b'" :selected="2" />
                </div>
                <!-- 분기처리 추가 여자, 횟수제-->
                <div v-if="userRatePlans.onGoing && gender === 1" class="m-t-8 m-b-16">
                    <!-- <img class="m-r-4" src="@/assets/images/icons/info_pink.png" width="16px" height="16px" />

                    <div class="text" v-html="'약속 확정시 매칭권 1회가 사용됩니다'" /> -->
                </div>
            </div>
        </div>
        <BottomButton
            v-if="gender === 1"
            :disabled="disabled"
            @click="onClickSelect"
            label="지역 선택 및 약속 확정"
            :nonFixed="true"
        />
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionScheduleConfirm',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        time() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
        disabled() {
            return this.content.status === 1
        },
        gender() {
            return this.$store.getters.me.gender
        },
        introduction() {
            return {
                title: this.gender === 1 ? '약속 내용을 확인하고 만날 지역을 선택해주세요.' : '잠시만 기다려주세요!',
                desc:
                    this.gender === 1
                        ? '남성분이 일정을 선택하고, 만남 지역과 방식(커피 혹은 식사)을 입력해주셨어요.'
                        : '여성분이 일정을 확인하시면 자동으로 약속이 최종 확정됩니다!',
            }
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        hasUnpaid() {
            return (this.me || {}).products.some(p => {
                return p.product_id === 62
            })
        },
        places() {
            if (this.content.meet_place_3) {
                return [this.content.meet_place_1, this.content.meet_place_2, this.content.meet_place_3]
            }
            if (this.content.meet_place_2) {
                return [this.content.meet_place_1, this.content.meet_place_2]
            }
            return [this.content.meet_place_1]
        },
        isOnePlace() {
            return this.places.length === 1
        },
    },
    methods: {
        async onClickSelect() {
            const res = await this.$modal.custom({
                component: 'ModalSelectRegion',
                options: { places: this.places, content: this.content },
            })

            if (res) {
                this.onClickConfirm(res)
            }
        },
        async onClickConfirm(res) {
            // if (this.userRatePlans.onGoing || this.hasUnpaid) {
            const payload = { product_id: 1 }
            payload[`${this.$store.getters.me.gender === 1 ? 'a' : 'b'}_message_id`] = this.message.id
            payload.meet_place = res

            try {
                this.$loading(true)
                await scheduleService.createSchedule(this.content.schedule_id, payload)
                this.$store.dispatch('loadChat', { chatId: this.message.chat_id, force: true })
                this.$store.dispatch('loadMe')
                this.$loading(false)
            } catch (e) {
                this.$loading(false)
                this.$toast.error(e.data.msg)
            }
            // } else {
            //     this.$modal.basic({
            //         title: '프로그램 이용이 만료되었습니다',
            //         body: '약속을 진행하려면 프로그램을 구매해주세요',
            //         buttons: [
            //             {
            //                 label: 'CONFIRM',
            //                 class: 'btn-primary',
            //             },
            //         ],
            //     })
            // }

            this.$stackRouter.pop()

            // this.$stackRouter.push({
            //     name: 'SchedulePayPage',
            //     props: {
            //         userInfo: {
            //             photoUrl: this.photoUrl,
            //             age: this.age,
            //             region: this.content.region,
            //             job: this.content.job,
            //             message: this.message,
            //             schedule_id: this.content.schedule_id,
            //             date_confirmed: this.content.date_confirmed,
            //             meet_place: this.content.meet_place,
            //             meet_type_confirmed: this.content.meet_type_confirmed,
            //         },
            //     },
            // })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
}
.desc-detail {
    margin: 0 12px 8px 12px;
    color: #f64f56;
    font-size: 10px;
    line-height: 17px;
    font-style: italic;
}

.info {
    background: rgba(255, 61, 107, 0.07);
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    display: flex;
    align-items: center;
}
</style>
